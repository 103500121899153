.step-page {
  .check-and-copy-container {
    margin-top: 35px;

    svg {
      margin-right: 10px;
    }

    .complete-check-icon {
      width: 26px;
      height: 26px;
    }

    .align-left .copy-link-button {
      align-items: flex-start;
    }
  }

  .copy-link-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .read-only-field {
    label {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  .project-step-footer {
    margin-top: 75px;
    textarea {
      width: 100%;
    }

    svg {
      margin-right: 10px;
    }

    .complete-check-icon {
      width: 26px;
      height: 26px;
    }

    .additional-info-view {
      margin-bottom: 20px;
    }
  }
}

.step-option-radio {
  label {
    width: 100%;
  }

  .option-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 5px;
  }

  .option-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .option-modal-trigger {
    margin: 10px 0 20px;
  }

  img {
    max-height: 200px;
    max-width: 100%;
  }

  .video-button {
    margin: 10px 0 20px 10px;

    .video-button-text {
      margin-left: 10px;
    }
  }
}

.project-stepper {
  margin: 70px 0 30px;

  .btn {
    padding: 5px 10px;
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.determine-heat-loss-gain-page {
  img {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .determine-heat-loss-gain-form {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 15px;

    .form-group {
      margin-right: 10px;
      height: 80px;
    }
  }
}

.compact-image {
  max-width: 100%;
}
