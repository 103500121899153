$modal-header-height: 125px;
$modal-footer-height: 67px;

$font-lato: "Lato",sans-serif;
$font-noto: "Noto Sans",sans-serif;

$bg-grey: #FBFBFB;
$border: #BBBFC5;
$border-dark: #223140;

$accent: #26374A;
$accent-light: #1C578A;
$danger: #D3080C;
$info: #269ABC;
$success: #278400;
$warn: #EE7100;

$black: #000;
$white: #FFF;

$text-default: #333333;
$text-link: #2B4380;
$text-link-selected: #0535D2;
$text-link-visited: #7834BC;
