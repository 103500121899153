.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .row {
    margin: 8px auto;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 24px;
    margin-top: 0;
  }

  input {
    max-width: 100%;
    width: 100%;
  }

  .forgot-password-link {
    font-size: 10pt;
  }

  .logout-reason-message {
    color: $danger;
    font-size: smaller;
  }
}
