.create-project-page {
  h2 {
    font-family: 'Lato', sans-serif;
    font-size: 36px;
    font-weight: 600;
  }

  .section-label {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0 30px;
  }

  p {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .create-project-name {
    max-width: 600px;

    input {
      width: 100%;
    }
  }

  .create-project-progress {
    border: $border solid 1px;

    .create-project-progress-item {
      align-items: center;
      background-color: $accent;
      border-right: #415C77 solid 1px;
      color: #fff;
      display: flex;
      padding: 18px 30px;

      &.unavailable {
        background: #F7F7F7;
        color: #666;
      }

      &:last-of-type {
        border-right: none;
      }

      &.current {
        background-color: $accent-light;
        font-weight: 600;
      }

      a {
        color: #fff;
        display: block;
        height: 100%;
      }

      svg {
        margin-left: 15px;
      }
    }
  }
}

@media (max-width: 991px) {
  .create-project-page {
    .create-project-progress {
      .create-project-progress-item {
        border-right: none;
      }
    }
  }
}
