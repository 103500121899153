.checkbox,
.radio {
  label {
    padding-left: 30px;

    input {
      &[type='radio'],
      &[type='checkbox'] {
        margin-left: -25px;
        margin-top: 4px;
        transform: scale(1.5);
      }
    }
  }
}

.radio {
  label {
    input {
      &[type='radio'],
      &[type='checkbox'] {
        margin-top: 7px;
      }
    }
  }
}

.control-label {
  color: $accent;
}

.has-error {
  .checkbox,
  .checkbox-inline,
  .control-label,
  .radio,
  .radio-inline,
  &.checkbox label,
  &.checkbox-inline label,
  &.radio label,
  &.radio-inline label {
    color: inherit;
  }

  .form-control {
    border-color: inherit;
  }

  .help-block {
    color: $danger;
    font-weight: 600;
  }
}

.help-block {
  color: #666;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

label.control-label {
  font-family: $font-lato;
  font-size: 16px;
  font-weight: 600;
}

.full-width-field {
  width: 100%;

  input,
  select,
  textarea {
    width: 100%;
  }
}

.table-form-actions {
  margin-bottom: 20px;
}

.form-actions {
  margin-top: 60px;

  .btn {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.house-type-image {
  border: $border-dark solid 1px;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
}

.input-with-suffix {
  display: inline-block;
  margin-right: 5px;
}

.input-suffix {
  display: inline-block;
}
