@import 'variables';

.btn {
  &.btn-default {
    &:not(:hover) {
      border: 1px solid #DCDEE1;
    }

    &.btn-contrast {
      background-color: $accent;
      border-color: $white;
      color: $white;

      &:hover {
        background-color: $accent-light;
        color: $white;
      }
    }
  }

  &.btn-primary {
    border: 1px solid $accent;

    &.btn-contrast {
      background-color: #fff;
      color: $accent;
    }
  }
}

.btn-icon-left {
  svg {
    margin-right: 14px
  }
}

.btn-icon-right {
  svg {
    margin-left: 14px
  }
}

.button-row {
  .btn {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .button-row {
    margin-bottom: 15px;

    &:last-of-type {
       margin-bottom: 0;
    }

    .btn {
      margin: 2px 0;
    }
  }
}
