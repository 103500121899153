#main-app-content {
  @import 'checklist';
  @import 'button';
  @import 'component';
  @import 'core';
  @import 'createProject';
  @import 'dashboard';
  @import 'layout';
  @import 'license';
  @import 'form';
  @import 'login';
  @import 'nav';
  @import 'project';
  @import 'responsive';
  @import 'step';
  @import 'videos';
}

@import 'modal';

#wb-info .brand img,
#wb-info .brand object {
  max-width: 100%;
}
