@import 'variables';

.comments {
  h2 {
    margin-bottom: 30px;
  }

  .comment {
    border-bottom: $accent solid 1px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    white-space: pre-wrap;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }

    .comment-meta {
      font-style: italic;
      font-size: 16px;
      font-weight: 400;
      line-height: 26.4px;
      margin-bottom: 15px;
    }
  }
}

.modal-dialog {
  background: #fff;
}

.pagination-container {
  text-align: center;

  .pagination {
    border: 1px solid $border-dark;

    &>li {
      &.disabled {
        display: inline;
        pointer-events: none;
      }

      &.active {
        &>span {
          background-color: $accent-light;
        }
      }

      &>a {
        color: #223140;
        text-decoration: underline;
      }

      &>a,
      &>span {
        background-color: transparent;
        border: none;
        font-size: 18px;
        margin: 0;
        min-width: 54px;
        text-align: center;

        &:hover {
          background-color: $accent-light;
          color: $white;
        }

        &>span {
          border: none;
        }
      }
    }
  }
}

.progress {
  background-color: $white;
  border: $accent solid 1px;
  box-shadow: none;
  border-radius: 5px;
  height: 15px;

  .progress-bar {
    background-color: $accent-light;
  }
}

.well {
  background-color: $bg-grey;
  border: 1px solid $border;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  font-size: 20px;

  .well-header {
    background-color: $accent;
    color: $white;
    font-size: 16px;
  }

  .well-title {
    margin-bottom: 30px;
  }

  .well-actions {
    .btn {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.well-with-header {
  padding: 0;

  .well-header {
    padding: 20px 30px;
  }

  .well-content {
    padding: 20px 30px;
  }
}

.well-dark {
  background-color: $accent;
  color: $white;
}

@media (max-width: 991px) {
  .pagination-container {
    .pagination {
      &>li {
        span {
          min-width: auto;
        }
      }
    }
  }
}
